{
  "name": "tegeco-web-app",
  "version": "0.2.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.8",
    "@angular/cdk": "18.2.8",
    "@angular/common": "18.2.8",
    "@angular/compiler": "18.2.8",
    "@angular/core": "18.2.8",
    "@angular/forms": "18.2.8",
    "@angular/material": "18.2.8",
    "@angular/platform-browser": "18.2.8",
    "@angular/platform-browser-dynamic": "18.2.8",
    "@angular/router": "18.2.8",
    "@azure/msal-angular": "3.1.0",
    "@azure/msal-browser": "3.27.0",
    "dayjs": "^1.10.7",
    "puppeteer": "^23.10.4",
    "rxjs": "7.8.1",
    "tk-assets": "0.0.145",
    "tk-sass-common": "0.0.29",
    "tk-ui-components": "0.18.1",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "18.2.12",
    "@angular-eslint/builder": "18.3.1",
    "@angular-eslint/eslint-plugin": "18.4.3",
    "@angular-eslint/eslint-plugin-template": "18.4.3",
    "@angular-eslint/schematics": "18.3.1",
    "@angular-eslint/template-parser": "18.3.1",
    "@angular/cli": "18.2.8",
    "@angular/compiler-cli": "18.2.8",
    "@types/jasmine": "~4.3.0",
    "@types/jasminewd2": "~2.0.10",
    "@types/node": "^12.20.50",
    "@typescript-eslint/eslint-plugin": "^8.18.0",
    "@typescript-eslint/parser": "^8.18.0",
    "eslint": "9.16.0",
    "eslint-plugin-import": "latest",
    "eslint-plugin-jsdoc": "latest",
    "eslint-plugin-prefer-arrow": "latest",
    "jasmine-core": "5.5.0",
    "jasmine-spec-reporter": "7.0.0",
    "karma": "6.4.4",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.1",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "karma-sonarqube-unit-reporter": "0.0.23",
    "typescript": "5.4.5"
  }
}
