export const environment = {
  appVersion: require('../../package.json').version,
  type: 'stage',
  production: false,
  DAM_SERVICE: {
    tenant: 'TGC',
    url: 'https://dam-stage.admiral-cloud.vilua.de/v1/'
  },
  BASE_MEDIA_URL: 'https://media.vilua.de/tk/ecoach/',
  DEV_SETTINGS: {
    objectiveDeletionEnabled: false,
    participantCreationEnabled: false
  },
  MS_AZURE_SETTINGS: {
    authority: 'https://login.microsoftonline.com/777634b8-6549-48dd-89f9-71c677fea243',
    clientId: '62c96186-387e-424c-b695-685d2fd9f938',
    apiScopes: [
      'api://f4e5a338-fd8a-4b48-8395-64e276d511a3/access_as_user'
    ]
  }
};
